import React, {
    useState,
    useEffect,
    useRef,
} from 'react';
import { ReactComponent as AndroidLogo } from './android.svg';
import { ReactComponent as AppleLogo } from './apple.svg';
import videos from './video';

type Step = {
    text: string;
    src: string;
}

const INSTRUCTIONS_COPY = [
    'For iPhone: hover over the QR using your camera app and press the highlighted yellow square or the prompt at the top of your screen.',
    'For Android: hover over the QR using your camera app and press the prompt at the bottom of your screen.',
];

const IOS_TEMP: Step[] = [...videos.ios, ...videos.android]
    .map((src, i) => ({ text: INSTRUCTIONS_COPY[i], src }));

export const Instruction: React.FC<{}> = () => {
    const [activeIndex, setActiveIndex] = useState<number>(0);
    const [isAnimating, setIsAnimating] = useState(true);

    const instructionRef: React.MutableRefObject<HTMLDivElement | null> = useRef(null);

    useEffect(() => {
        setIsAnimating(true);

        setTimeout(() => {
            const nextIndex = activeIndex % IOS_TEMP.length;

            // If ref node is currently mounted in DOM.
            if (instructionRef.current) {
                const scrollTarget = Array.from(instructionRef.current.children)[nextIndex] as HTMLDivElement;

                // If target video wrapper div exists.
                if (scrollTarget) {
                    const video = (scrollTarget.children[0].children[0] as HTMLVideoElement);

                    try {
                        video.currentTime = 0;
                        video.play();
                    } catch (e) {
                        console.log(e);
                    } finally {
                        setIsAnimating(false);
                    }
                }
            }
        }, 2000);
        
    }, [activeIndex]);

    let logosClassName = 'instruction__logos';
    // TODO => Fix this predicate.
    if (activeIndex === 0) {
        logosClassName = `${logosClassName} instruction__logos--apple-active`;
    }

    return (
        <section
            className="signage__child instruction"
            
        >
            <div className={logosClassName}>
                <AndroidLogo className="instruction__logo instruction__logo--android"/>
                <span className="instruction__logo-slash">|</span>
                <AppleLogo className="instruction__logo instruction__logo--apple"/>
            </div>
            <div
                ref={instructionRef}
                className="instruction-card__wrapper"
            >
            {IOS_TEMP.map(({ src, text }, i) => {
                let instructionStepClassName = 'instruction__step instruction-card';

                return (
                    <div
                        className={instructionStepClassName}
                        key={i}
                        style={{
                            // zIndex: activeIndex === i ? 1 : 0,
                            opacity: (activeIndex === i && !isAnimating)
                                ? 1
                                : 0,
                        }}
                    >
                        <div className="instruction-card__video-wrapper">
                            <video
                                className="instruction-card__video"
                                src={src}
                                muted
                                onEnded={() => setActiveIndex(activeIndex => (activeIndex + 1) % IOS_TEMP.length)}
                            />
                        </div>
                        <div className="instruction-card__description">
                            {/* {i + 1}. {text} */}
                            {text}
                        </div>
                    </div>
                )
            })}
            </div>
        </section>
    )
}