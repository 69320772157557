import React, { useEffect, useState } from 'react';
import { Header } from '../Header/Header';
import { CapacityInformation } from '../../../shared/payloads';
import { Spinner } from '../Spinner';

// Interval to query capacity service.
const CAPACITY_INTERVAL_TIMEOUT = 5000;

export const Capacity = () => {
	const [capacityInformation, setCapacityInformation] = useState<CapacityInformation>(null);
	const rooms: CapacityInformation['zones'] = capacityInformation
		? capacityInformation.zones //capacityInformation.zones.filter(({ subZone }) => !subZone) -- commented filter for now
		: [];

	useEffect(() => {
		// Declare function to fetch capacity and update state.
		const fetchCapacity = async () => {
			try {
				const res = await fetch('/api/capacity');
				const capacityInformation: CapacityInformation = await res.json();

				setCapacityInformation(capacityInformation);
			} catch (e) {
				console.log(e);
			}
		};

		// Immediately fetch capacity.
		fetchCapacity();

		// Set up interval to fetch capacity information.
		const capacityInterval = setInterval(fetchCapacity, CAPACITY_INTERVAL_TIMEOUT);

		// On unmount, clear interval.
		return () => {
			if (capacityInterval) {
				clearInterval(capacityInterval);
			}
		};
	}, []);


    return (
        <div className="capacity">
			<div className="capacity__header-wrapper">
            	<Header href="/admin" />
			</div>
			<div className="capacity__description">
				<h1 className="capacity__description-header">Capacity Dashboard</h1>
				<h2 className="capacity__description-header capacity__description-header--sub">
					Current Total Capacity: {
						rooms.length
							? rooms.reduce((acc, room) => acc + room.counts.maleTotal, 0)
							: ''
					}
				</h2>
			</div>
            <div className="capacity__content">
                <div
                    className="capacity__cards"
                    style={{
                        opacity: rooms.length ? 1 : 0,
                    }}
                >
                    {rooms.map((room, i) => <RoomCard key={i} room={room}/>)}
                </div>
                {!rooms.length && <Spinner className="capacity__spinner"/>}
            </div>
        </div>
    )
};

type RoomCardProps = { room: CapacityInformation['zones'][0] };
const RoomCard: React.FC<RoomCardProps> = ({ room }) => {
    const calculatedPercentage = Math.round(room.counts.maleTotal / room.capacity * 100);

	return (
		<div className="capacity__progress-card">
			<ProgressCircle
				name={room ? room.name : null}
				percent={room ? calculatedPercentage : 0}
				currentCapacity={room.counts.maleTotal}
				maxCapacity={room.capacity}
			/>
			<div
				className="capacity__caption-wrapper"
				style={{
					opacity: room ? 1 : 0,
				}}
			>
				<div className="capacity__caption">
					{room &&
						<>
							<h2 className="capacity__caption-header">
								{room.name}
							</h2>
							<span className="capacity__caption-bump">
								at {calculatedPercentage}% capacity
                            </span>
							<div className="capacity__caption-stats">
								<p>Current Capacity: {room.counts.maleTotal}</p>
								<p>Max Capacity: {room.capacity}</p>
							</div>
						</>
					}
				</div>
			</div>
		</div>
	);
}

type ProgressCircleProps = {
    name: string | void;
	percent: number;
	currentCapacity: number;
	maxCapacity: number;
};
const ProgressCircle: React.FC<ProgressCircleProps> = ({
	percent,
	currentCapacity,
	maxCapacity,
    name,
}) => {
	const radius = 99;
	const circumference = radius * 2 * Math.PI;
	// Uncomment this for progress circle.
	// const offset = circumference - percent / 100 * circumference;
	const offset = circumference - (1 * circumference); // circumference - percent / 100 * circumference;

	let progressRingClassName = "progress-ring"

	if (percent >= 90) {
		progressRingClassName = `${progressRingClassName} progress-ring--error`;
	} else if (percent >= 60) {
		progressRingClassName = `${progressRingClassName} progress-ring--warn`;
	}

	return (
		<div className={progressRingClassName}>
			<div className="progress-ring__caption">
				<span className="progress-ring__caption-text progress-ring__caption-text--percent">
					{currentCapacity}
                </span>
				<span className="progress-ring__caption-text progress-ring__caption-text--bump">
					<span className="progress-ring__caption-text--over">{maxCapacity}</span>
				</span>
			</div>

			<svg
				className="progress-ring__graphic"
				height="200"
				width="200"
			>
				<circle
					className="progress-ring__circle progress-ring__circle--background"
					fill="transparent"
					r={radius}
					cx="100"
					cy="100"
				/>

				<rect
					className="progress-ring__wave"
					height="200"
					width="200"
					fill="currentColor"
					transform={`
						translate(0, ${(200 * (1 - percent / 100))})
					`}
				/>	

				<circle
					className="progress-ring__circle progress-ring__circle--base"
					fill="transparent"
					r={radius}
					cx="100"
					cy="100"
				/>
				<circle
					className="progress-ring__circle progress-ring__circle--progress"
					stroke="black"
					strokeWidth="5"
					fill="transparent"
					r={radius}
					cx="100"
					cy="100"
					style={{
						strokeDasharray: `${circumference} ${circumference}`,
						strokeDashoffset: `${offset}`
					}}
				/>
			</svg>
		</div>
	)
};
