import React, { useEffect, useState } from 'react';
import {
	BrowserRouter as Router,
	Switch,
	Route
} from 'react-router-dom';
import { Event } from './components/Event/Event';
import { Instruction } from './components/Instruction/Instruction';
import { Admin } from './components/Admin/Admin';
import { Capacity } from './components/Capacity/Capacity';
import { StorageContext, StorageContextType } from './contexts/storageContext';
import { EventTemplateSummary } from '../shared/payloads';
import { windowStorage } from './constants/storage';

/**
 * Routing root component.
 */
const App: React.FC = () => {
	const [storage, setStorage] = useState<StorageContextType>({});

	// Read from localStorage on initial render.
	// Set up sto and subsequent interval to reset state each day.
	useEffect(() => {
		const checkStorage = () => {
			// Get the stored "saved at" time and event object.
			const storedSavedAt = windowStorage.getItem('savedAt');
			const storedEvent = windowStorage.getItem('displayEvent');

			// If we do have a "saved at" time stored w/ an event object
			if (storedSavedAt && storedEvent) {
				// Try-catch block is for JSON parse fail.
				try {
					const savedAt = parseInt(storedSavedAt);

					// Get the day it was saved and today's day
					const savedDay = new Date(savedAt).getDate();
					const today = new Date(Date.now()).getDate();

					// If today is greater than the day the event was last saved at
					// Then the event data has gone stale (as new events are available for each new day)
					if (today > savedDay) {
						// Clear the local storage and localStorageContext state.
						windowStorage.clear();
						setStorage({});
					} else {
						const event: EventTemplateSummary = JSON.parse(storedEvent);
						setStorage({ event });
					}

				} catch (e) {
					console.log(e);
					windowStorage.clear();
					setStorage({});
				}
			}
		}

		// Immediately call function to check if storage is up to date.
		checkStorage();

		// Just check every hour to see if we need to update our storage.
		const interval = setInterval(checkStorage, 3600000);

		// Clean up interval on unmount to prevent memory leak.
		// Not strictly necessary as app should never unmount.
		return () => {
			if (interval) {
				clearInterval(interval);
			}
		}
	}, []);

	return (
		<StorageContext.Provider value={storage}>
			<Router>
				<Switch>
					<Route exact path="/">
						<Signage />
					</Route>
					<Route path="/admin">
						<Admin setStorage={setStorage} />
					</Route>
					<Route path="/capacity">
						<Capacity />
					</Route>
				</Switch>
			</Router>
		</StorageContext.Provider>
	)
};

/**
 * Main display.
 */
const Signage: React.FC = () => (
	<div className="signage">
		<Event />
		<Instruction />
	</div>
);

export default App;
