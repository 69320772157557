import React, {
	useState,
	useEffect,
	useContext,
} from 'react';
import { Link } from 'react-router-dom';
import qrCode from 'qrcode';
import { Header } from '../Header/Header';
import { StorageContext } from '../../contexts/storageContext';
import { TemplateId } from '../../constants/enum';

/***
 * Generate URL to be encoded in QR.
 * @param {string} templateId - event id.
 * @returns {string} - URL for event.
 */
const generateTicketUrl = (templateId: string) => {

	// Create a date string for this past midnight
	const date = new Date();
	date.setHours(0, 0, 0);

	const todayDate = date.toISOString();

	// If this is a membership template, scan membership.
	return (
		templateId !== TemplateId.Membership
			? `https://tickets.barnesfoundation.org/orders/316/tickets?eventId=${templateId}&cdEventIds=${templateId}&date=${todayDate}`
			: 'https://members.barnesfoundation.org/'
	);
};

const DEFAULT_WIDTH = 550;

export const Event: React.FC = () => {
	const { event } = useContext(StorageContext)
	const [qrSrc, setQrSrc] = useState('' as string);

	
	useEffect(() => {

		const fetchEvents = async () => {
			if (event) {
				const dataUrl = generateTicketUrl(event.id);

				const src = await qrCode.toDataURL(
					dataUrl,
					{
						width: DEFAULT_WIDTH,
						margin: 0,
						color: {
							dark: '#282828',
							light: '#fff',
						},
					} as qrCode.QRCodeToDataURLOptions,
				);
				setQrSrc(src);
			}
		};

		fetchEvents();
	}, [event]);



	return (
		<section className="signage__child event">
			<Header />
			<div className="event__content">
				<div className="event__detail-wrapper">
					<div className="event__detail event-card">

						{/** Only display the event information when an event is available */}
						{event &&
							(() => {
								const { name, description } = event;

								return (
									<>
										<div className="event-card__qr-wrapper">
											<img
												className="event-card__qr"
												src={qrSrc}
												alt={`QR Code for the event "${name}".`}
											/>
										</div>
										<h3 className="event-card__caption">Today's Event</h3>
										<h1 className="event-card__header event-card__header--title">{name}</h1>
										<div className="event-card__description" dangerouslySetInnerHTML={{ __html: description }} />
									</>
								)
							})()
						}

						{/** Display when no event has been selected yet */}
						{!event &&
							<div className="event__detail-warn">
								<h1>Please select an event in the <Link to="/admin">admin panel</Link> </h1>
							</div>
						}

					</div>
				</div>
				<footer className="event__footer">
					<div className="event__footer-line"></div>
					<span className="event__footer-text">Use your phone's camera to scan the barcode above. You'll be directed to purchase tickets for this event.</span>
				</footer>
			</div>
		</section>
	)
}
