// const iOSmovie1 = `${process.env.REACT_APP_S3_ASSETS}/1.mp4`;
const iOSmovie2 = `${process.env.REACT_APP_S3_ASSETS}/2.mp4`;
// const iOSmovie3 = `${process.env.REACT_APP_S3_ASSETS}/3.mp4`;

const androidMovie1 = `${process.env.REACT_APP_S3_ASSETS}/android.mp4`;

export default {
    // ios: [iOSmovie1, iOSmovie2, iOSmovie3],
    ios: [iOSmovie2],
    android: [androidMovie1],
};
