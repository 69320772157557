import React from 'react';
import { ReactComponent as BarnesLogo } from './barnesLogo.svg';
import { Link } from 'react-router-dom';

type HeaderProps = { href?: string };
export const Header: React.FC<HeaderProps> = ({
    href = '/capacity' // Default route is capacity
}) => (
    <div className="header">
        <Link to={href}>
            <BarnesLogo className="header__barnes-logo" />
        </Link>
    </div>
);
