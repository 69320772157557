import React, { useEffect, useState } from 'react';
import { EventTemplateSummary } from '../../../shared/payloads';
import { Redirect } from 'react-router';
import { Header } from '../Header/Header';
import { StorageContextType } from '../../contexts/storageContext';
import { windowStorage } from '../../constants/storage';
import { Spinner } from '../Spinner';

type AdminProps = {
	setStorage: React.Dispatch<React.SetStateAction<StorageContextType>>;
}
export const Admin: React.FC<AdminProps> = ({ setStorage }) => {

	const [events, setEvents] = useState<EventTemplateSummary[]>([]);
	const [selectedOption, setSelectedOption] = useState<string>('');
	const [redirect, setRedirect] = useState<boolean>(false);

	const onSave = () => {
		// Get the selected event and set it to local storage along with time of save
		const event = events.find((event) => event.id === selectedOption);
		windowStorage.setItem('displayEvent', JSON.stringify(event));
		windowStorage.setItem('savedAt', Date.now().toString());

		if (event) {
			// Update context.
			setStorage({ event });

			// Redirect to the main app screen
			setRedirect(true);
		}
	}

	useEffect(() => {
		const fetchEvents = async () => {
			const response = await fetch('/api/events');
			const events = await response.json() as EventTemplateSummary[];

			setEvents(events);
		};

		fetchEvents();

		// Check if we have an event in local storage
		const storedEvent = windowStorage.getItem('displayEvent');

		// If we have a selected event
		if (storedEvent) {
			const event = JSON.parse(storedEvent);

			// Set it as the selected option
			setSelectedOption(event.id);
		}

		return () => {
			setRedirect(false);
		}
	}, []);

	if (redirect) return <Redirect to="/" push />

	return (
		<div className="admin">
			<Header href="/" />
			<div className="content-wrapper">
				<div className="content">
					<p className="content__select-header">Select an Event to display</p>
					<div>
					{events.length
						? events.map((event, index) => {
							const { id, startTime, endTime } = event;
							const s = new Date(startTime).toLocaleTimeString('en-US', { hour: 'numeric' });
							const e = new Date(endTime).toLocaleTimeString('en-US', { hour: 'numeric' });

							return (
								<div key={index} className="content__select-item" onClick={() => setSelectedOption(id)}>
									<input type="radio" name="event" id={`${id}`} checked={selectedOption === id} onChange={() => setSelectedOption(id)} />
									<span className="radio"></span>
									<div className="info">
										<label className="label">{event.name}</label>
										{/** Do not display for events w/ no times. */}
										{(startTime && endTime) && <p className="date">{s} &mdash; {e}</p>}
									</div>
								</div>
							)
						})
						: <Spinner />}
					</div>
					{Boolean(events.length) && <button className="save-btn" onClick={onSave}>Save</button>}
				</div>
			</div>
		</div>
	)
}