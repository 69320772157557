import React from 'react';

type SpinnerProps = {
    text?: string;
    className?: string;
    style?: React.CSSProperties;
};
export const Spinner: React.FC<SpinnerProps> = ({
    text,
    className,
    style,
}) => (
    <>
        <div
            className={`spinner ${className || ''}`}
            style={style || {}}
        >
            <div className="bounce1"></div>
            <div className="bounce2"></div>
            <div className="bounce3"></div>
        </div>
        {text && <div className="spinner__text">{text}</div>}
    </>
);

type SpinnerSuspenseProps = {
    isLoaded: boolean,
    className?: string
};
export const SpinnerSuspense: React.FC<SpinnerSuspenseProps> = ({ children, isLoaded, className }) => (
    <>
        {
            isLoaded
                ? children
                : <Spinner className={className} />
        }
    </>
)